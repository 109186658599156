import React, { PropsWithChildren } from "react";

import './text.css'

export enum FontType {
    HEADING_L = "heading_lg",
    HEADING_S = "heading_sm",
    BODY = "body",
    LABEL_L = "label_lg",
    LABEL_S = "label_sm",
}

export enum ColorType {
    WHITE = "white",
    COOL_WHITE = 'cool_white',
    BLACK = "black",
    COOL_BLACK = "cool_black",
    PRIMARY = 'primary',
    DANGER = 'danger'
}

export enum FontFamily {
    OBJECT = 'object',
    MARS = 'mars'
}

export enum TextAlign {
    CENTER = 'center',
    RIGHT = 'right',
    LEFT = 'left'
}

type TagType = keyof Pick<
    JSX.IntrinsicElements,
    "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "label"
>;

type TextProps = {
    className?: string;
    color?: ColorType;
    font?: FontType;
    weight?: number | string;
    type?: TagType;
    onClick?: (e: any) => void;
    htmlFor?: string;
    family?: string;
    style?: any;
    align?: TextAlign;
};

export const Text: React.FC<PropsWithChildren<TextProps>> = ({
    type: Element = "p",
    color,
    align,
    font = FontType.BODY,
    weight = 400,
    className,
    children,
    onClick,
    htmlFor,
    family,
    style,
}) => (
    <Element
        className={` ${className} text__${font} text__${color} text__${weight} text__${family} text__${align}`}
        onClick={onClick}
        htmlFor={htmlFor}
        style={style}
    >
        {children}
    </Element>
);
