import React from 'react';

import './card.css'
import { FontFamily, FontType, Text } from '../Text';

interface CardProps {
  data: {
    fields: {
      Name: string;
      Investments: string;
      website: string;
      TicketSize: string;
      sector: string;
      last_raised: number;
      about: string;
      Description: string;
      Company: string;
    };
    id: string;
  };
  onchange: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  recommendedFunds?: any;
}

const Card: React.FC<CardProps> = ({ data, onchange, checked, recommendedFunds }) => {
  const { Company, Investments, website, TicketSize } = data.fields;

  return (
    <>
      <div className={`card ${checked ? 'selectedCard' : ''}`}>
      {/* <div>
          {recommendedFunds && recommendedFunds.map((fund: any) => fund.trim().toLowerCase()).includes(Company.trim().toLowerCase()) &&
            <div className='recommended'>
              <button className='recommended-btn'>Recommended</button>
            </div>
          }
        </div> */}
        <div className='card-details'>
          <input
            id={Company}
            type='checkbox'
            checked={checked}
            name={Company}
            value={data.id || ''}
            onChange={onchange('SelectedCompaniesByInvestor')}
            className='checkbox'
            style={{
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              appearance: 'none',
              border: '2px solid #3282ff',
              backgroundColor: checked ? '#1dc115' : '#fff',
              cursor: 'pointer',
            }}
          />
          <label htmlFor={Company}></label>
          <Text font={FontType.HEADING_L} family={FontFamily.OBJECT} weight={700} className='company'>{Company}</Text>
          <div>
            <a className='company-link' href={website} target='_blank' rel="noreferrer">
              <span className='website'>VISIT WEBSITE</span>
              <svg width="8" height="8" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5.74L4.53 1.21L4.5 1.17L0.77 1.23L2 0L6.54 0.05L6.59 4.59L5.36 5.84L5.42 2.11L5.38 2.08L0.85 6.59L0 5.74Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>

        <div className='stage-investment-wrapper'>
          {/* <div className='stage'>
            <Text font={FontType.LABEL_L}>Stage Focus</Text>
            <Text font={FontType.BODY} family={FontFamily.OBJECT} weight={700} >{TicketSize}</Text>
          </div> */}
          <div className='investment'>
            <Text font={FontType.LABEL_L}>Key Investments</Text>
              {Investments && Investments.trim().split(',').map((e, i) => 
                <p className='investment-company'>{e}</p>
             )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
