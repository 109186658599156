import React, { useEffect, useState } from "react";
import axios from 'axios'

import { BASE_ID_USER, API_KEY, BASE_API, COMPANIES_TABLE, INVESTORS_TABLE, FOUNDER_TABLE, FOUNDER_LAYOUT_TEXT } from './constant/index';
import { isValidEmail } from "./constant/string";
import { Header } from "./component/Header";
import Dashboard from "./container/Dashboard";
import { Footer } from "./component/Footer";

interface FormState {
  Name: string;
  CompanyName: string;
  CompanyCode: string;
  Email: string;
  SelectedCompaniesByInvestor: string[];
}

interface CompanyRecord {
  fields: {
    CompanyCode: string;
  };
}

interface InvestorData {
  [key: string]: any;
}

interface ErrorMessages {
  [key: string]: string;
}

type EventHandler = (e: React.ChangeEvent<HTMLInputElement>) => void;

function App() {
  const [allCompanies, setAllCompanies] = useState<CompanyRecord[]>([]);
  const [interestedInvestorData, setInterestedInvestorData] = useState<InvestorData[]>([]);
  const [investorsList, setInvestorsList] = useState<InvestorData[]>([]);
  const [isInvestorAvailable, setIsInvestorAvailable] = useState<boolean>(false);
  const [form, setForm] = useState<FormState>({
    Name: '',
    CompanyName: '',
    CompanyCode: '',
    Email: '',
    SelectedCompaniesByInvestor: [],
  });
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessages | string>('');
  const [step, setStep] = useState<number>(1);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [companyCode, setCompanyCode] = useState<string[]>([]);
  const [investorId, setInvestorId] = useState<string[]>([]);
  const [layoutText, setLayoutText] = useState<string>('')
  const [showThankYouModal, setShowThankYouModal] = useState<boolean>(false)
  // console.log(form.CompanyCode, allCompanies)

  const getCompanies = () => {
    axios.get(`${BASE_API}/${BASE_ID_USER}/${COMPANIES_TABLE}`,
      { headers: { "Authorization": `Bearer ${API_KEY}` } })
      .then((res: any) => setAllCompanies(res.data.records))
      .catch((err: any) => console.log(err))
  }

  const getSingleCompany = (companyCode: any) => {
    axios.get(`${BASE_API}/${BASE_ID_USER}/${COMPANIES_TABLE}?filterByFormula=CompanyCode='${companyCode}'`,
      { headers: { "Authorization": `Bearer ${API_KEY}` } })
      .then((res: any) => setInterestedInvestorData(res.data.records))
      .catch((err: any) => console.log(err))
  }

  const getInvestorList = async (id: any) => {
    const res = await axios.get(`${BASE_API}/${BASE_ID_USER}/${INVESTORS_TABLE}/${id}`,
      { headers: { "Authorization": `Bearer ${API_KEY}` } })
    setInvestorsList(preState => [...preState, res.data])
  }

  useEffect(() => {
    if (investorId) {
      investorId.forEach((id, i) => setTimeout(() => getInvestorList(id), i * 220))
    }
  }, [investorId])

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    interestedInvestorData.map(data => {
      if (data.fields.InvestorsTable) {
        data.fields.InvestorsTable.map((id: any) => setInvestorId(preId => [...preId, id]))
      } else {
        setIsInvestorAvailable(true)
      }
    })

  }, [interestedInvestorData])

  useEffect(() => {
    axios.get(`${BASE_API}/${BASE_ID_USER}/${FOUNDER_LAYOUT_TEXT}/`,
      { headers: { "Authorization": `Bearer ${API_KEY}` } })
      .then((res: any) => setLayoutText(res.data.records))
      .catch((err: any) => console.log(err))
  }, [])


  useEffect(() => {
    allCompanies.map(company => (
      setCompanyCode(preCode => [...preCode, company.fields.CompanyCode])
    ))
  }, [allCompanies])

  let isCompanyCodeValid = companyCode.includes(form.CompanyCode)

  const handleFormChange = (field: keyof FormState): EventHandler => (e) => {
    const { value, checked } = e.target;

    setChecked((currentState) => ({
      ...currentState,
      [e.target.name]: e.target.checked
    }));

    const { SelectedCompaniesByInvestor } = form

    if (field === "SelectedCompaniesByInvestor" && checked) {
      setForm((currentState) => ({
        ...currentState,
        [field]: [...SelectedCompaniesByInvestor, value]
      }));
    } else if (field === 'SelectedCompaniesByInvestor') {
      setForm((currentState) => ({
        ...currentState,
        [field]: SelectedCompaniesByInvestor.filter((e) => e !== value)
      }));
    } else {
      setForm((currentState) => ({
        ...currentState,
        [field]: value
      }));
    }
  }

  function getErrors(form: FormState): ErrorMessages {
    const errors: ErrorMessages = {};
    if (!form.Name) {
      errors.Name = "Enter Name";
    }
    if (!form.Email) {
      errors.Email = "Enter Email";
    } else if (!isValidEmail(form.Email)) {
      errors.Email = "Enter Valid Email";
    }
    if (!form.CompanyName) {
      errors.CompanyName = "Enter company name";
    }
    if (!form.CompanyCode) {
      errors.CompanyCode = "Enter company code";
    } else if(!isCompanyCodeValid) {
      errors.CompanyCode = "Enter valid company code";
    }
    return errors;
  }

  const handleSubmitFrom = async (e: any) => {
    e && e.preventDefault();
    setLoading(true)
    const errors = getErrors(form);

    if (Object.keys(errors).length > 0) {
      !showError && setShowError(true);
      return;
    }

    axios.post(`${BASE_API}/${BASE_ID_USER}/${FOUNDER_TABLE}/`,
      {
        "fields": {
          "Name": form.Name,
          "Email": form.Email,
          "CompanyName": form.CompanyName,
          "CompanyCode": form.CompanyCode,
          "SelectedCompaniesByInvestor": form.SelectedCompaniesByInvestor
        },
      }, { headers: { "Authorization": `Bearer ${API_KEY}` } }).then((res: any) => setShowThankYouModal(true)).catch((err: any) => console.log(err))
  }

  const nextStep = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();

    const errors = getErrors(form);

    if (Object.keys(errors).length > 0) {
      !showError && setShowError(true);
      setErrorMessage(errors)
      return;
    }
    if (step === 1) {
      getSingleCompany(form.CompanyCode)
    }
    setStep(step + 1)
    window.scrollTo(0, 0)
  }

  const prevStep = () => {
    setStep(step - 1);
  }

  const disable = form.Name === '' || form.CompanyName === '' || form.CompanyCode === '' || form.Email === ''
  const disableAllCompanies = form.SelectedCompaniesByInvestor.length === 0

  return (
    <>
      <Header step={step} />
      <Dashboard
        step={step}
        onchange={handleFormChange}
        values={form}
        onsubmit={handleSubmitFrom}
        disable={disable}
        nextStep={nextStep}
        prevStep={prevStep}
        // allCompanies={allCompanies}
        disableAllCompanies={disableAllCompanies}
        investorsList={investorsList}
        checked={checked}
        loading={loading}
        interestedInvestorData={interestedInvestorData}
        errorMessage={errorMessage}
        isInvestorAvailable={isInvestorAvailable}
        layoutText={layoutText}
        showThankYouModal={showThankYouModal}
      />
      <Footer />
    </>
  );
}

export default App;
