export const scrollToTop = (element: HTMLCollectionOf<Element>) => {
    if (element instanceof Element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      try {
        window.scroll({
          behavior: "smooth",
          top: 0
        });
      } catch (err) {
        if (err instanceof TypeError) {
          window.scroll(0, 0);
        } else {
          throw err;
        }
      }
    }
  };
  