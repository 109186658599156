import React from 'react';
import Card from '../component/Card';
import { Button } from '../component/Button';

import './home.css'
import { ColorType, FontType, Text, TextAlign } from '../component/Text';

interface SelectedCompaniesProps {
  companies: any[];
  handleBackPage: () => void;
  handleSelectedSubmit: () => void;
  onchange: any;
  loading: boolean;
  showThankYouModal: boolean;
  dashboardPageContent: string;
  // interestedInvestorData: any;
  thankYou: string;
}

export const SelectedCompanies: React.FC<SelectedCompaniesProps> = ({
  companies,
  handleBackPage,
  handleSelectedSubmit,
  onchange,
  loading,
  showThankYouModal,
  dashboardPageContent,
  // interestedInvestorData,
  thankYou
}) => {
  const disable = companies.length === 0;
  // const recommendedFunds = (interestedInvestorData[0]?.fields.Recommended)?.split(',')

  const paragraphs = thankYou && thankYou.split('\n').map((item, key) => {
    return <Text key={key} style={{paddingBottom: '8px'}} >{item}</Text>;
  });

  return (
    <div className='homePageWrapper'>
      {/* <Text className="selectedSubPara">
        {dashboardPageContent}
      </Text> */}
      <Text font={FontType.HEADING_L}>
        Selected Funds
      </Text>
      <div className="card-wrapper">
        {companies
          ? companies.map((data, i) => (
            <Card
              key={i}
              data={data}
              onchange={onchange}
              checked={true}
              // recommendedFunds={recommendedFunds}
            />
          ))
          : <p>Loading .....</p>}
      </div>
      <Button onClick={handleBackPage} className='selected-companies-btn active'>Back</Button>
      <Button disable={disable || loading} onClick={handleSelectedSubmit} className={`${disable ? 'disable' : 'active'}`}>
        Submit
      </Button>
      <div>
        {showThankYouModal && (
          <div className="modal-overlay modal-open">
            <div className="modal-wrapper">
              <Text font={FontType.HEADING_L} color={ColorType.WHITE} className='modalHeading'>THANK YOU!</Text>
              <Text color={ColorType.WHITE} align={TextAlign.CENTER} className='modalPara'>{paragraphs}</Text>
              <a className='btn modalBtn' href='https://elevationcapital.com/' target='_blank' rel="noreferrer">
                VISIT ELEVATION CAPITAL WEBSITE
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

