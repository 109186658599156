import React from 'react'

import { FormContainer } from './FormContainer';
import { ColorType, Text } from '../Text';

import './login.css'

interface Form {
  Name: string;
  Email: string;
  CompanyName: string;
  CompanyCode: string;
}

interface LoginProps {
  onchange: any;
  value: Form;
  nextStep: any;
  disable: boolean;
  layoutText: any;
  errorMessage: any
}


// const paragraphs = dashboardPopUpContent && dashboardPopUpContent.split('\n').map((item, key) => {
//   return <Text key={key} >{item}</Text>;
// });

export const Login: React.FC<LoginProps> = ({ onchange, value, nextStep, disable, layoutText, errorMessage }) => {
  const paragraphs = layoutText[0]?.fields.Description && layoutText[0]?.fields.Description.split('\n').map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, key: React.Key | null | undefined) => {
    return <Text key={key} className='para' >{item}</Text>;
  });
  return (
    <div className='formContainer'>
      <div className='textWrapper'>
        <h2 className='formHeading'>Welcome to <br /> <span >Elevation <br /> Connect:</span> The Mumbai 2024 Edition</h2>
      </div>
      <div className='outerFormWrapper'>
        <div className="outerWrapper">
          <Text color={ColorType.WHITE}>{paragraphs}</Text>
          <Text className='subHeading' color={ColorType.PRIMARY}>{layoutText[0]?.fields.ShortLine}</Text>
          <FormContainer
            onchange={onchange}
            form={value}
            disable={disable}
            submit={nextStep}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </div>
  )
  
}