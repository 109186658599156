import React, { PropsWithChildren } from "react";

import './button.css'

export enum BtnVariant {
    NONE = "none",
    OUTLINED = "outline",
}

export enum BtnBorder {
    DOTTED = 'dotted',
    DAS = 'das',
    NONE = 'none'
}

export enum BtnSize {
    S = "small",
    M = "medium",
    L = "large",
}

export enum BtnColor {
    PRI = 'pri',
    WHITE = 'white',
    COOL_WHITE = 'cool_white',
}

export enum BtnBackground {
    BLACK = 'black_bg',
    PRI = 'pri_bg'
}

type ButtonProps = {
    className?: string;
    variant?: BtnVariant;
    size?: BtnSize;
    color?: BtnColor;
    background?: BtnBackground;
    border?: BtnBorder;
    disable?: boolean
    type?: string;
    onClick?: any;
};

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    className,
    children,
    variant = BtnVariant.OUTLINED,
    size = BtnSize,
    color = BtnColor,
    background = BtnBackground,
    border = BtnBorder,
    disable,
    type,
    onClick,
    ...rest
}) => (
    <button
        disabled={disable}
        onClick={onClick}
        className={`customBtn ${className} type btn__${variant} btn__${size} btn__${color} btn__${background} btn__${border}`}
        {...rest}
    >
        {children}
    </button>
);
