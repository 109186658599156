import React, { useEffect, useRef, useState } from 'react';
import Card from '../component/Card';
import { scrollToTop } from '../constant/device';

import './home.css'
import { ColorType, FontFamily, FontType, Text, TextAlign } from '../component/Text';
import { Button } from '../component/Button';

interface HomeProps {
  onchange: any;
  nextStep: any;
  disable: boolean;
  checked: any
  interestedInvestorData: any;
  investorsList: any;
  isInvestorAvailable: any;
  dashboardPageContent: string;
  dashboardPopUpContent: string;
}

export const Home: React.FC<HomeProps> = ({
  onchange,
  nextStep,
  disable,
  checked,
  investorsList,
  isInvestorAvailable,
  dashboardPageContent,
  interestedInvestorData,
  dashboardPopUpContent
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const [showPopUp, setShowPopUp] = useState<boolean>(false)

  const newData = [...new Map(investorsList.map((item: any) => [item.fields.Company, item])).values()].sort((a: any, b: any) => a.fields.Company.localeCompare(b.fields.Company));
  // const newData = [...new Map(investorsList.map((item: any) => [item.fields.Company, item])).values()]
  const recommendedFunds = (interestedInvestorData[0]?.fields.Recommended)?.split(',')

  useEffect(() => {
    setShowPopUp(true)
  }, [])

  useEffect(() => {
    if (myRef.current) {
      scrollToTop(myRef.current.getElementsByClassName('card-wrapper'));
    }
  }, []);

  const popUpClose = () => {
    setShowPopUp(false)
  }

  const paragraphs = dashboardPopUpContent && dashboardPopUpContent.split('\n').map((item, key) => {
    return <Text key={key} >{item}</Text>;
  });

  return (
    <div className='homePageWrapper'>
      <Text className="selectedSubPara">
        {dashboardPageContent}
      </Text>
      {isInvestorAvailable && <Text>Loading Investors</Text>}
      <div ref={myRef} className="card-wrapper">
        {newData
          ? newData.map((data: any, i: number) => (
            <Card
              key={i}
              data={data}
              onchange={onchange}
              checked={checked[data.fields.Company] || false}
              recommendedFunds={recommendedFunds}
            />
          ))
          : <h2>loading</h2>}
      </div>
      <Button disable={disable} onClick={nextStep} className={`${disable ? 'disable' : 'active'}`}>
        Submit
      </Button>
      {showPopUp &&
        <div className="modal-overlay modal-open">
          <div className="modal-wrapper popup-wrapper">
            <button onClick={popUpClose} className='modal-close' >
              <Text color={ColorType.WHITE} font={FontType.HEADING_L} family={FontFamily.OBJECT} >
                X
              </Text>
            </button>
            <Text color={ColorType.WHITE} align={TextAlign.CENTER}>
              {paragraphs}
            </Text>
          </div>
        </div>
      }
    </div>
  );
};
