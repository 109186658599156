import React from "react";

import './login.css'
import { ColorType, Text } from "../Text";

interface Form {
  Name: string;
  Email: string;
  CompanyName: string;
  CompanyCode: string;
}

interface FormContainerProps {
  onchange: (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  form: Form;
  disable: boolean;
  submit: (event: React.FormEvent<HTMLFormElement>) => void;
  errorMessage: any;
}

export const FormContainer: React.FC<FormContainerProps> = ({ onchange, form, disable, submit, errorMessage }) => (
  <form onSubmit={submit} action="" className="loginFormWrapper">
    <div>
      <input
        className='input'
        type="text"
        name="Name"
        placeholder="Name"
        value={form.Name}
        onChange={onchange("Name")}
      />
    </div>
    <div>
      <input
        className='input'
        type="email"
        name="Email"
        placeholder="Email Address"
        value={form.Email}
        onChange={onchange('Email')}
      />
    </div>
    <div>
      <input
        className='input'
        type="text"
        name="CompanyName"
        placeholder="Company Name"
        value={form.CompanyName}
        onChange={onchange('CompanyName')}
      />
    </div>
    <div>
      <input
        className='input'
        type="text"
        name="CompanyCode"
        placeholder="Company Code"
        value={form.CompanyCode}
        onChange={onchange('CompanyCode')}
    />
      {errorMessage && <Text color={ColorType.DANGER}>{errorMessage.companyCode}</Text> }
    </div>
    <div>
      <input
        className={`btn ${disable ? 'disabled' : ''}`}
        type="submit"
        value="Next"
        disabled={disable}
      />
    </div>
  </form>
);
