import './header.css'
import Logo from '../../assets/logo.png'
import ColorLogo from '../../assets/colorLogo.png'
interface HeaderProps {
  step: number
}

export const Header: React.FC<HeaderProps> = ({ step }) => {
  return (
    <header className='header'>
      {
        step === 1 ?
          <img src={Logo} alt="Elevation Connect" width={217} height={101} />
          :
          <img src={ColorLogo} alt="Elevation Connect" width={217} height={101} />
      }
    </header>
  )
}
