import React from 'react';

import { Login } from '../component/Login';
import { Home } from './Home';
import { SelectedCompanies } from './SelectedCompanies';

interface DashboardProps {
  step: number;
  onchange: any;
  values: any;
  onsubmit: any;
  disable: boolean;
  nextStep: () => void;
  prevStep: () => void;
  disableAllCompanies: boolean;
  checked: any;
  loading: boolean;
  interestedInvestorData: any;
  investorsList: any[];
  errorMessage: any;
  isInvestorAvailable: boolean;
  layoutText: any;
  showThankYouModal: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({
  step,
  onchange,
  values,
  onsubmit,
  disable,
  nextStep,
  prevStep,
  disableAllCompanies,
  checked,
  loading,
  interestedInvestorData,
  investorsList,
  errorMessage,
  isInvestorAvailable,
  layoutText,
  showThankYouModal,
}) => {
  const getSelectedCompanies: any[] = [];

  values.SelectedCompaniesByInvestor.forEach((e: any) =>
    investorsList.forEach((company) => {
      if (company.id === e) {
        getSelectedCompanies.push(company);
      }
    })
  );

  switch (step) {
    case 1:
      return (
        <Login
          errorMessage={errorMessage}
          onchange={onchange} value={values}
          nextStep={nextStep} disable={disable}
          layoutText={layoutText}
        />
      );
    case 2:
      return (
        <Home
          nextStep={nextStep}
          onchange={onchange}
          disable={disableAllCompanies}
          checked={checked}
          interestedInvestorData={interestedInvestorData}
          investorsList={investorsList}
          isInvestorAvailable={isInvestorAvailable}
          dashboardPageContent={layoutText[0]?.fields.DashboardPageContent}
          dashboardPopUpContent={layoutText[0]?.fields.DashboardPopUpContent}
        />
      );
    case 3:
      return (
        <SelectedCompanies
          handleBackPage={prevStep}
          handleSelectedSubmit={onsubmit}
          companies={getSelectedCompanies}
          onchange={onchange}
          loading={loading}
          showThankYouModal={showThankYouModal}
          dashboardPageContent={layoutText[0]?.fields.DashboardPageContent}
          // interestedInvestorData={interestedInvestorData}
          thankYou={layoutText[0]?.fields.ThankYou}
        />
      );
    default:
      return null;
  }
};

export default Dashboard;
