import React from 'react'

import './footer.css'
import { ColorType, FontFamily, FontType, Text } from '../Text'

export const Footer = () => {
  return (
    <div className='footer'>
     <Text color={ColorType.WHITE} font={FontType.HEADING_S} family={FontFamily.MARS} className='footer-title'>BUILT FOR THE AUDACIOUS</Text>
     <Text className='copyright'>© 2024 Elevation Capital. All rights reserved</Text>
    </div>
  )
}
